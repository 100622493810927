import 'libs/a11y/focus.service';
import 'libs/a11y/external-links-hint';
import 'libs/tracking';
import 'libs/scroll-to-anchor';
import 'libs/styles/_reset.scss';
import '../scss/_typography.scss';
import '../scss/_fonts.scss';
import '../scss/_common.scss';
import './components';
import { Provider } from 'libs/provider';
import { FocusService } from 'libs/a11y/focus.service';
import { initHomeAnimations } from './components/home/home-animations';
import { ComponentRegistry } from 'libs/components';
import { YoutubeIframeApiLoader } from 'libs/youtube-iframe-api-loader';
import { ClipboardService } from 'libs/clipboard.service';
import { PrivacyService } from './components/data-privacy/privacy-service';
import { Overlay, TooltipOutlet, TooltipService } from './components';
import { ReducedMotionQuery } from 'libs/a11y';
import { ModalService, ModalOutlet } from './components/modal';
import { BottomSheet } from './components/bottom-sheet/bottom-sheet';
function main() {
    var reducedMotionQuery = new ReducedMotionQuery();
    Provider.provide(ReducedMotionQuery, reducedMotionQuery);
    var focusService = new FocusService(reducedMotionQuery);
    Provider.provide(FocusService, focusService);
    var youtubeIframeApiLoader = new YoutubeIframeApiLoader();
    Provider.provide(YoutubeIframeApiLoader, youtubeIframeApiLoader);
    var clipboardService = new ClipboardService();
    Provider.provide(ClipboardService, clipboardService);
    var modalOutlet = new ModalOutlet();
    var modalService = new ModalService(modalOutlet);
    modalOutlet.appendTo(document.body);
    Provider.provide(ModalService, modalService);
    var tooltipService = new TooltipService(new TooltipOutlet(document.body));
    Provider.provide(TooltipService, tooltipService);
    var overlay = new Overlay();
    Provider.provide(Overlay, overlay);
    var bottomSheet = new BottomSheet();
    Provider.provide(BottomSheet, bottomSheet);
    var privacyService = new PrivacyService(overlay);
    Provider.provide(PrivacyService, privacyService);
    initHomeAnimations(focusService, reducedMotionQuery);
    youtubeIframeApiLoader.load();
    ComponentRegistry.observe();
}
window.onload = main;
