var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BottomSheet } from 'app/components/bottom-sheet/bottom-sheet';
import { Overlay } from 'app/components/overlay';
import { Component, ComponentRegistry } from 'libs/components';
import { Provider } from 'libs/provider';
var ProjectCard = /** @class */ (function (_super) {
    __extends(ProjectCard, _super);
    function ProjectCard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggler = _this.host.querySelector('.project-card__toggler');
        _this.content = _this.host.querySelector('.project-card__details');
        _this.contentInner = _this.host.querySelector('.project-card__details-inner');
        return _this;
    }
    ProjectCard.prototype.onInit = function () {
        this.registerListeners();
        if (this.host.classList.contains('project-card--expanded')) {
            // Load project list
            this.content.style.height = "".concat(this.contentInner.clientHeight, "px");
        }
        this.setActiveStepInCenter();
    };
    ProjectCard.prototype.getDependencies = function () {
        this.overlay = Provider.get(Overlay);
        this.bottomSheet = Provider.get(BottomSheet);
    };
    ProjectCard.prototype.setActiveStepInCenter = function () {
        var stepsScrollContainer = this.host.querySelector('.project-card__progress');
        var activeStep = this.host.querySelector('.project-steps__step--active');
        if (stepsScrollContainer && activeStep) {
            var rectContainer = stepsScrollContainer.getBoundingClientRect();
            if (rectContainer.width < stepsScrollContainer.scrollWidth) {
                var rectActiveStep = activeStep.getBoundingClientRect();
                var centerPosition = rectContainer.width / 2 - rectActiveStep.width / 2;
                var scrollPos = rectActiveStep.left - centerPosition;
                if (scrollPos > 0) {
                    stepsScrollContainer.scrollTo({
                        left: scrollPos,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };
    ProjectCard.prototype.loadProjectDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dataUrl, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dataUrl = this.contentInner.dataset['url'];
                        if (!dataUrl) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetch(dataUrl, {
                                method: 'GET',
                                headers: {
                                    'X-Requested-With': 'XMLHttpRequest',
                                },
                            }).then(function (res) { return res.text(); })];
                    case 1:
                        response = _a.sent();
                        this.contentInner.innerHTML = response;
                        ComponentRegistry.attachAllComponentsUnder(this.contentInner);
                        this.registerDetailListeners();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectCard.prototype.registerDetailListeners = function () {
        var _this = this;
        this.host
            .querySelectorAll('a[overlay]')
            .forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault();
                _this.overlay.loadUrl(link.getAttribute('href'));
            });
        });
    };
    ProjectCard.prototype.registerListeners = function () {
        var _this = this;
        var mobileDetailLink = this.host.querySelector('a[mobile-detail]');
        if (mobileDetailLink) {
            mobileDetailLink.addEventListener('click', function (event) {
                event.preventDefault();
                var url = mobileDetailLink.getAttribute('href');
                if (url) {
                    _this.bottomSheet.openUrl(url);
                }
            });
        }
        if (this.toggler) {
            this.host.addEventListener('accordionSizeChanged', function (event) {
                var customEvent = event;
                _this.content.style.height = "".concat(_this.content.clientHeight + customEvent.detail.sizeChange, "px");
                _this.host.dispatchEvent(new CustomEvent('contentChanged', {
                    detail: { sizeChange: customEvent.detail.sizeChange },
                    bubbles: true,
                }));
            });
            this.toggler.addEventListener('click', function () {
                var sizeChange;
                if (_this.host.classList.contains('project-card--expanded')) {
                    _this.host.classList.remove('project-card--expanded');
                    sizeChange = _this.content.clientHeight * -1;
                    _this.content.style.height = '0';
                    _this.host.dispatchEvent(new CustomEvent('contentChanged', {
                        detail: { sizeChange: sizeChange },
                        bubbles: true,
                    }));
                }
                else {
                    _this.host.classList.add('project-card--expanded');
                    _this.content.style.height = "".concat(_this.contentInner.clientHeight, "px");
                    sizeChange = _this.contentInner.clientHeight;
                    _this.host.dispatchEvent(new CustomEvent('contentChanged', {
                        detail: { sizeChange: sizeChange },
                        bubbles: true,
                    }));
                    _this.content.style.height = "".concat(_this.contentInner.clientHeight, "px");
                    sizeChange = _this.contentInner.clientHeight;
                    _this.host.dispatchEvent(new CustomEvent('contentChanged', {
                        detail: { sizeChange: sizeChange },
                        bubbles: true,
                    }));
                    // Load project list
                    _this.loadProjectDetails().then(function () {
                        requestAnimationFrame(function () {
                            _this.content.style.height = "".concat(_this.contentInner.clientHeight, "px");
                            sizeChange = _this.contentInner.clientHeight;
                            _this.host.dispatchEvent(new CustomEvent('contentChanged', {
                                detail: { sizeChange: sizeChange },
                                bubbles: true,
                            }));
                        });
                    });
                }
            });
        }
        else {
            this.registerDetailListeners();
        }
    };
    return ProjectCard;
}(Component));
export { ProjectCard };
ComponentRegistry.register('.project-card', ProjectCard);
