var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Component, ComponentRegistry } from 'libs/components';
var ProjectListFilterToggler = /** @class */ (function (_super) {
    __extends(ProjectListFilterToggler, _super);
    function ProjectListFilterToggler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOpen = false;
        _this.reloadOnReset = false;
        return _this;
    }
    ProjectListFilterToggler.prototype.onInit = function () {
        this.reloadOnReset = this.host.hasAttribute('reload-on-reset');
        this.getElements();
        this.registerListeners();
    };
    ProjectListFilterToggler.prototype.getElements = function () {
        this.filterControl = this.host.querySelector('.filter-control');
        this.resetButton = this.host.querySelector('.filter-reset');
        this.selectField = this.host.querySelector('.select-field');
        this.checkboxes = this.host.querySelectorAll('input[type="checkbox"]');
        this.form = this.host.querySelector('.filter-collapsible');
    };
    ProjectListFilterToggler.prototype.registerListeners = function () {
        var _this = this;
        if (this.filterControl) {
            this.filterControl.addEventListener('click', function () { return _this.onToggle(); });
        }
        if (this.resetButton) {
            this.resetButton.addEventListener('click', function (event) {
                return _this.onReset(event);
            });
        }
        if (this.form) {
            this.form.addEventListener('submit', function (event) { return _this.applyFilter(event); });
        }
    };
    ProjectListFilterToggler.prototype.onReset = function (event) {
        event.preventDefault();
        if (this.reloadOnReset) {
            location.href = location.pathname;
        }
        else {
            this.form.reset();
            this.selectField.dispatchEvent(new Event('change'));
            this.checkboxes.forEach(function (el) { return (el.checked = false); });
            history.pushState({}, document.title, "".concat(location.pathname));
            document
                .querySelectorAll('.project_list[data-url], .project_category[data-url]')
                .forEach(function (projectList) {
                projectList.dispatchEvent(new CustomEvent('filter_list', {
                    detail: null,
                }));
            });
        }
    };
    ProjectListFilterToggler.prototype.onToggle = function () {
        this.isOpen = !this.isOpen;
        this.hostClass('project-filters--expanded', this.isOpen);
        this.filterControl.setAttribute('aria-expanded', this.isOpen.toString());
    };
    ProjectListFilterToggler.prototype.applyFilter = function (event) {
        event.preventDefault();
        var formData = new FormData(this.form);
        var queryString = __spreadArray(__spreadArray([], __spreadArray([], formData.getAll('filter-category'), true).map(function (x) { return "filter-category=".concat(x); }), true), __spreadArray([], formData.getAll('filter-region'), true).map(function (x) { return "filter-region=".concat(x); }), true).join('&');
        history.pushState({}, document.title, "".concat(location.pathname, "?").concat(queryString));
        document
            .querySelectorAll('.project_list[data-url], .project_category[data-url]')
            .forEach(function (projectList) {
            projectList.dispatchEvent(new CustomEvent('filter_list', {
                detail: queryString,
            }));
        });
    };
    return ProjectListFilterToggler;
}(Component));
ComponentRegistry.register('.project-filters', ProjectListFilterToggler);
