var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, ComponentRegistry } from 'libs/components';
var ProjectAccordion = /** @class */ (function (_super) {
    __extends(ProjectAccordion, _super);
    function ProjectAccordion() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.header = _this.host.querySelector('.project-accordion__header');
        _this.projectCount = _this.host.querySelector('.project-accordion__project-count');
        _this.content = _this.host.querySelector('.project-accordion__content');
        _this.contentInner = _this.host.querySelector('.project-accordion__content-inner');
        _this.dataLoaded = false;
        return _this;
    }
    ProjectAccordion.prototype.onInit = function () {
        this.registerListeners();
        if (this.host.getAttribute('initial-expanded') === 'True') {
            this.host.classList.add('project-accordion--open');
            this.content.style.height = "".concat(this.contentInner.clientHeight, "px");
            this.loadTeaserList();
        }
        else {
            this.loadProjectCount();
        }
    };
    ProjectAccordion.prototype.updateCounter = function () {
        var projectCount = this.contentInner.querySelectorAll('.project-card').length;
        this.projectCount.innerHTML = "(".concat(projectCount, ")");
    };
    ProjectAccordion.prototype.loadTeaserList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var detailUrl, response, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.dataLoaded) return [3 /*break*/, 3];
                        detailUrl = this.host.dataset['url'];
                        if (!detailUrl) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetch(detailUrl, {
                                method: 'GET',
                                headers: {
                                    'X-Requested-With': 'XMLHttpRequest',
                                },
                            })];
                    case 1:
                        response = _b.sent();
                        _a = this.contentInner;
                        return [4 /*yield*/, response.text()];
                    case 2:
                        _a.innerHTML = _b.sent();
                        this.dataLoaded = true;
                        setTimeout(function () {
                            _this.content.style.height = "".concat(_this.contentInner.clientHeight, "px");
                            _this.updateCounter();
                        }, 200);
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProjectAccordion.prototype.loadProjectCount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var detailUrl, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        detailUrl = this.host.dataset['url'];
                        if (!detailUrl) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetch("".concat(detailUrl, "?count=true"), {
                                method: 'GET',
                                headers: {
                                    'X-Requested-With': 'XMLHttpRequest',
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        response.text().then(function (html) {
                            _this.projectCount.innerHTML = html;
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectAccordion.prototype.registerListeners = function () {
        var _this = this;
        this.header.addEventListener('click', function () {
            if (_this.host.classList.contains('project-accordion--open')) {
                _this.host.classList.remove('project-accordion--open');
                _this.content.style.height = '0';
                // Remove `this.dataLoaded = false;` from here if you want
                // to keep the content and not reload on every click
                _this.dataLoaded = false;
            }
            else {
                _this.host.classList.add('project-accordion--open');
                _this.content.style.height = "".concat(_this.contentInner.clientHeight, "px");
                _this.loadTeaserList();
            }
        });
        this.host.addEventListener('contentChanged', function (event) {
            var customEvent = event;
            _this.content.style.height = "".concat(_this.content.clientHeight + customEvent.detail.sizeChange, "px");
        });
        this.content.addEventListener('transitionend', function (event) {
            if (event.target === _this.content &&
                _this.host.classList.contains('project-accordion--open')) {
                if (_this.content.clientHeight !== _this.contentInner.clientHeight) {
                    _this.content.style.height = "".concat(_this.contentInner.clientHeight, "px");
                }
            }
        });
    };
    return ProjectAccordion;
}(Component));
export { ProjectAccordion };
ComponentRegistry.register('.project-accordion', ProjectAccordion);
